
import Http from './Http'

export default {
    async getTeamCompetitions() {
        return await Http.get(`/team/teams/competitions`)
    },
    async getTeamSquad(team_code,team_id) {
        return await Http.get(`/team/squad?type=${team_code}&team_id=${team_id}`)
    },
    async removeSquadPlayer(id) {
        return await Http.delete(`/team/squad/${id}`)
    },
    async addSquadPlayer(data) {
        return await Http.post(`/team/squad`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async editSquadPlayer(id, data) {
        return await Http.post(`/team/squad/${id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async pullSquad(data) {
        return await Http.post(`/team/squad/pull`, data)
    },
    async removeSquadAdditionalImage(id) {
        return await Http.delete(`/team/squad/additional_image/${id}`)
    },
    async getTeamsByCompetition(competition_id) {
        return await Http.get(`/team/teams/competitions?competition_id=${competition_id}`)
    },
    async editTeams(data) {
        return await Http.post('/team/teams', data)
    },
    async removeTeamData(team_id) {
        return await Http.delete(`/team/teams/${team_id}`)
    },
    async competitionFixtures(competition_id) {
        return await Http.get(`/team/competition/${competition_id}/fixtures`)
    },
    async competitionFixturesByRound(competition_id, round_id) {
        return await Http.get(`/team/competition/${competition_id}/fixtures?round=${round_id}`)
    },
    async featuredMatches(competition_id) {
        return await Http.get(`/team/match/featured/${competition_id}`)
    },
    async addfeaturedMatch(match_id, data) {
        return await Http.post(`/team/match/${match_id}/featured`, data)
    },
    async removefeaturedMatch(match_id, data) {
        return await Http.delete(`/team/match/${match_id}/featured`, data)
    },
    async getFixtures(type = '') {
        return await Http.get(`/team/fixtures?type=${type}&customer_id=1`)
    },
    async linkSquadPlayer(id, data) {
        return await Http.post(`/team/squad/linkplayer/${id}`, data)
    },

}


